/***************************
****** Dependencies 📚 *****
***************************/
@import "~office-ui-fabric-react/dist/sass/Fabric.scss";
@import "~bulma/sass/utilities/initial-variables.sass";
@import "~bulma/sass/utilities/functions.sass";
@import "~bulma/sass/utilities/derived-variables.scss";
@import "~bulma/sass/utilities/mixins.sass";

/***************************
******** Theme 🎨 **********
***************************/
@import "./_variables.scss";
@import "./_helpers.scss";
@import "./_base.scss";

//Dependencies: We Import bulma after variables to add new colors
@import "~bulma/bulma.sass";
@import "~bulma-extensions/dist/css/bulma-extensions.min.css";

/***************************
********** Pages 📖 ********
***************************/
//Public
@import "./pages/public/_layout.scss";

//Private
@import "./pages/private/_layout.scss";
@import "./pages/private/procedure/all.scss";
@import "./pages/private/procedure/one.scss";
@import "./pages/private/administration/all.scss";

/***************************
******* Components 🧱 *****
***************************/
@import "./components/divider.scss";
@import "./components/card.scss";
@import "./components/loader.scss";
@import "./components/workflow.scss";
@import "./components/scrollToTop.scss";
@import "./components/groupComponent.scss";
